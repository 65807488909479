import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "AppIndex",
    component: () => import("../views/index.vue"),
    meta: { title: "首页" },
  },
  {
    path: "/login",
    name: "IndexPage",
    component: () => import("../views/login.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/dkl",
    name: "dklPage",
    component: () => import("../views/dkl.vue"),
    meta: { title: "展示" },
  },
  {
    path: "/ins",
    name: "insPage",
    component: () => import("../views/ins/index.vue"),
    children: [
      {
        path: "auth",
        name: "authPage",
        component: () => import("../views/ins/auth.vue"),
        meta: { title: "授权" },
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
